.section {
    @apply py-16 lg:py-24;

    &-title {
        @apply prose-headings:text-3xl lg:prose-headings:text-8xl;

        .captions {
            @apply mt-4;
        }

        .home-intro &, .home-featured &, .home-point &, .home-news &, .about-value &, .product-applied &, .news-related & {
            @apply prose-headings:font-light;
        }

        .home-intro &, .home-featured &, .product-applied & {

            .captions {
                @apply mx-auto lg:max-w-[580px];
            }
        }

        .home-about & {
            @apply mt-14 lg:mt-20;
        }

        .home-point & {

            @include media-breakpoint-up(lg) {
                @apply flex flex-wrap w-5/6 prose-headings:leading-normal prose-headings:flex-1 prose-headings:text-4xl;

                .captions {
                    @apply pl-6 mt-0 w-[55%];
                }
            }
        }

        .home-news & {
            @apply flex items-center justify-between;

            .captions {
                @apply mt-0;
            }
        }

        .services-intro & {

            .captions {
                @apply mx-auto mt-3;

                @include media-breakpoint-up(lg) {
                    @apply w-3/4;
                }
            }
        }

        .services-package &, .control-package &, .tech-intro &, .distribution-intro &, .contact-point &, .contact-location &, .page-template & {

            @include media-breakpoint-up(lg) {
                @apply prose-headings:text-5xl;
            }
        }

        .tech-intro & {
            @apply pb-6 border-b border-b-[#E8E8E8];

            .captions {
                @apply mx-auto;

                @include media-breakpoint-up(lg) {
                    @apply mt-6 w-3/4;
                }
            }
        }

        .contact-location & {

            .captions {
                @apply mt-2;
            }
        }
    }
}