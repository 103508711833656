.treeview {

    > a {
        @apply flex items-center;

        span {
            @apply ml-auto;
        }
    }

    &__menu {
        @apply hidden;

        &-list {
            @apply ml-4 mt-4;
        }
    }

    &__open {

        > a {

            > span {
                transform: rotate(-90deg);
            }
        }
    }
}