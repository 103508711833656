.about {

    &-intro {

        &__body {

            &-content {
                @apply prose-headings:mb-4 prose-headings:text-2xl;

                @include media-breakpoint-up(lg) {
                    @apply prose-headings:text-4xl prose-headings:mb-6;
                }

                .post-detail {
                    @apply mb-6;

                    @include media-breakpoint-up(lg) {
                        @apply mb-10;
                    }
                }
            }
        }
    }

    &-value {
        @apply bg-right-top;
        background-size: 25%;

        @include media-breakpoint-up(md) {
            background-size: 150px;
        }
    }
}