@charset "UTF-8";
@import "~datatables.net-dt/css/dataTables.dataTables.css";
@import "~datatables.net-responsive-dt/css/responsive.dataTables.css";
:root {
  --base-color: #000;
  --white-color: #fff;
  --navbar-height: 120px;
  --loading-size: 1.25rem;
  --loading-sm-size: 1rem;
  --loading-lg-size: 1.5rem;
  --loading-xl-size: 2rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 699px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 1030px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 698.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 699.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1029.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 699px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 700px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1030px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("/assets/js/vendor/slick/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("/assets/js/vendor/slick/fonts/slick.eot");
  src: url("/assets/js/vendor/slick/fonts/slick.eot?#iefix") format("embedded-opentype"), url("/assets/js/vendor/slick/fonts/slick.woff") format("woff"), url("/assets/js/vendor/slick/fonts/slick.ttf") format("truetype"), url("/assets/js/vendor/slick/fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

.slick {
  @apply hidden;
}
.slick-dotted.slick--banner, .slick-slider.slick--banner {
  @apply mb-4 lg:mb-8;
}
.slick--category .slick-list {
  overflow: unset;
}
.slick--banner .slick-slide .item {
  @apply block;
}
.slick--banner .slick-slide .item-image {
  @apply aspect-[3/1] overflow-hidden;
}
.slick--banner .slick-slide .item-image img {
  @apply w-full h-full object-center object-cover;
}
.slick--category .slick-slide {
  @apply lg:w-[800px];
}
.slick--category .slick-slide .item {
  @apply block transition ease-in-out;
}
.slick--category .slick-slide .item-image {
  @apply aspect-[2/1] prose-img:w-full prose-img:h-full prose-img:object-center prose-img:object-cover;
}
.slick--category .slick-slide .item-content {
  @apply -mt-8 lg:-mt-10 prose-headings:text-primary prose-headings:font-normal lg:prose-headings:text-4xl;
}
.slick--category .slick-slide .item-content > h4 {
  @apply inline-block py-4 px-12 lg:px-24 bg-white bg-opacity-50 rounded-rg;
  backdrop-filter: blur(30px);
}
.slick--product .slick-slide {
  @apply mx-2 lg:mx-5;
}
.slick--product-detail .slick-slide .item-image {
  @apply prose-img:mx-auto prose-img:h-[175px];
}
@media (min-width: 1030px) {
  .slick--product-detail .slick-slide .item-image {
    @apply prose-img:h-[300px];
  }
}
.slick--category .slick-slide.slick-active .item-image {
  @apply scale-110;
}
.slick--category .slick-slide.slick-active .item-content {
  @apply -mt-6 lg:-mt-5;
}
@media (min-width: 1030px) {
  .slick--category .slick-slide.slick-active .item:hover .item-image {
    transform: scale(1.175);
  }
}
.slick--category .slick-slide.slick-active .item:hover .item-content {
  @apply lg:mt-0 prose-headings:text-white;
}
.slick--category .slick-slide.slick-active .item:hover .item-content > h4 {
  @apply bg-primaryDark;
}
.slick-right, .slick-left {
  @apply w-10 h-10 inline-flex items-center justify-center bg-primary rounded-full overflow-hidden cursor-pointer z-10 transition ease-in-out hover:bg-primaryDark;
}
.slick--banner .slick-right, .slick--category .slick-right, .slick--product-detail .slick-right, .slick--banner .slick-left, .slick--category .slick-left, .slick--product-detail .slick-left {
  @apply absolute top-1/2 -translate-y-1/2;
}
.slick--product .slick-right, .slick--product .slick-left {
  @apply absolute top-1/4 -translate-y-1/4;
}
.slick--banner .slick-right {
  @apply right-2 lg:right-10;
}
.slick--category .slick-right {
  @apply right-4 lg:right-[300px];
}
.slick--product .slick-right, .slick--product-detail .slick-right {
  @apply right-0 lg:right-12;
}
.slick--banner .slick-left {
  @apply left-2 lg:left-10;
}
.slick--category .slick-left {
  @apply left-4 lg:left-[300px];
}
.slick--product .slick-left, .slick--product-detail .slick-left {
  @apply left-0 lg:left-12;
}
.slick-dots {
  @apply -bottom-4 lg:-bottom-8;
}
.slick-dots > li {
  @apply inline-flex items-center justify-center w-3 h-3 mx-1;
}
.slick-dots > li.slick-active button {
  @apply rotate-45 bg-primary;
}
.slick-dots > li button {
  @apply bg-gray w-2 h-2 before:hidden;
  border-radius: 2px;
}
.dt-paging.paging_full_numbers {
  @apply text-center;
}
@media (min-width: 1030px) {
  .dt-paging.paging_full_numbers {
    @apply mt-10;
  }
}

div.dt-container .dt-paging .dt-paging-button {
  @apply mx-2 p-0 font-bold text-[#484E54] appearance-none;
  min-width: unset;
}
div.dt-container .dt-paging .dt-paging-button:hover {
  @apply border-0 text-black !important;
  background: transparent !important;
}

div.dt-container .dt-paging .dt-paging-button.current {
  @apply text-2xl text-primary border-0 !important;
  background: transparent;
}
div.dt-container .dt-paging .dt-paging-button.current:hover {
  @apply text-primaryDark border-0 !important;
  background: transparent !important;
}

.dt-filter {
  @apply grid gap-8;
}
@media (min-width: 1030px) {
  .dt-filter {
    @apply flex gap-10;
  }
}
.dt-filter__left {
  @apply inline-flex items-center;
}
.dt-filter .form-select {
  @apply ml-4 border-primary focus:border-primaryDark !important;
}
@media (min-width: 1030px) {
  .dt-filter .form-select {
    width: 200px !important;
  }
}
.dt-filter .form-input {
  @apply border-primary focus:border-primaryDark placeholder:italic !important;
}
@media (min-width: 1030px) {
  .dt-filter .form-input {
    width: 450px !important;
  }
}

.dt-container .dt-layout-row .dt-search {
  @apply hidden;
}

table.dataTable > thead > tr > th {
  @apply p-3 border-b-0 bg-primaryDark text-white;
}
@media (min-width: 1030px) {
  table.dataTable > thead > tr > th {
    @apply px-10 text-1xl;
  }
}
table.dataTable > thead > tr > th:first-child {
  @apply rounded-tl-sm;
}
table.dataTable > thead > tr > th:last-child {
  @apply rounded-tr-sm;
}
table.dataTable > tbody > tr:nth-of-type(even) > td {
  @apply bg-grayLight;
}
table.dataTable > tbody > tr > td {
  @apply p-3 font-bold;
}
@media (min-width: 1030px) {
  table.dataTable > tbody > tr > td {
    @apply px-10;
  }
}
table.dataTable .dataTables_length {
  display: none;
}

.btn {
  --primary-color: #00AAEA;
  --primary-color-invert: white;
  --primary-color-hover: #008ec4;
  --secondary-color: #F4D03F;
  --secondary-color-invert: white;
  --secondary-color-hover: #f2c71b;
  --light-color: #fff;
  --light-color-invert: #00AAEA;
  --light-color-hover: #ececec;
  --button-padding-y: 12px;
  --button-padding-x: 24px;
  --button-font-size: 1rem;
  --button-rounded: 4px;
  --button-sm-padding-y: 4px;
  --button-sm-padding-x: 8px;
  --button-sm-font-size: 0.875rem;
  --button-lg-padding-y: 12px;
  --button-lg-padding-x: 16px;
  --button-lg-font-size: 1.25rem;
  @apply inline-flex items-center justify-center border border-transparent font-semi cursor-pointer;
  padding: var(--button-padding-y) var(--button-padding-x);
  font-size: var(--button-font-size);
  border-radius: var(--button-rounded);
  outline: 0;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-gradient {
  border: unset;
}
.btn-sm {
  padding: var(--button-sm-padding-y) var(--button-sm-padding-x);
  font-size: var(--button-sm-font-size);
}
.btn-lg {
  padding: var(--button-lg-padding-y) var(--button-lg-padding-x);
  font-size: var(--button-lg-font-size);
}
.btn-primary {
  background-color: var(--primary-color) !important;
  color: var(--primary-color-invert);
}
.btn-primary svg {
  fill: var(--primary-color-invert);
}
.btn-primary:hover {
  background-color: var(--primary-color-hover) !important;
}
.btn-outline-primary {
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.btn-outline-primary svg {
  fill: var(--primary-color);
}
.btn-outline-primary:hover {
  color: var(--primary-color-invert);
  background-color: var(--primary-color) !important;
}
.btn-outline-primary:hover svg {
  fill: var(--primary-color-invert);
}
.btn-secondary {
  background-color: var(--secondary-color) !important;
  color: var(--secondary-color-invert);
}
.btn-secondary svg {
  fill: var(--secondary-color-invert);
}
.btn-secondary:hover {
  background-color: var(--secondary-color-hover) !important;
}
.btn-outline-secondary {
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}
.btn-outline-secondary svg {
  fill: var(--secondary-color);
}
.btn-outline-secondary:hover {
  color: var(--secondary-color-invert);
  background-color: var(--secondary-color) !important;
}
.btn-outline-secondary:hover svg {
  fill: var(--secondary-color-invert);
}
.btn-light {
  background-color: var(--light-color) !important;
  color: var(--light-color-invert);
}
.btn-light svg {
  fill: var(--light-color-invert);
}
.btn-light:hover {
  background-color: var(--light-color-hover) !important;
}
.btn-outline-light {
  border-color: var(--light-color);
  color: var(--light-color);
}
.btn-outline-light svg {
  fill: var(--light-color);
}
.btn-outline-light:hover {
  color: var(--light-color-invert);
  background-color: var(--light-color) !important;
}
.btn-outline-light:hover svg {
  fill: var(--light-color-invert);
}

.card {
  @apply transition ease-in-out overflow-hidden;
}
.card--news:hover .card-header img {
  @apply scale-110;
}
.card--news:hover .card-content__title {
  @apply prose-headings:text-primaryDark;
}
.card--news:hover .card-content__actions {
  @apply text-primaryDark;
}
.card--news:hover .card-content__actions::before {
  @apply bg-primaryDark;
}
.card--news:hover .card-content__actions .icon {
  @apply ml-4;
}
.card--news:hover .card-content__actions .icon svg {
  @apply fill-primaryDark;
}
.card--product:hover .card-header img {
  @apply scale-110;
}
.card--product:hover .card-content__actions .icon {
  @apply ml-4;
}
.card--value {
  @apply p-4;
}
@media (min-width: 1030px) {
  .card--value {
    @apply py-10 px-8;
  }
}
@media (min-width: 1030px) {
  .card--category:hover .card-header img {
    transform: scale(1.175);
  }
}
.card--category:hover .card-content__title {
  @apply prose-headings:text-white;
}
.card--category:hover .card-content__title > h4 {
  @apply bg-primaryDark;
}
.card--applied:hover .card-content__actions {
  @apply text-primaryDark;
}
.card--applied:hover .card-content__actions::before {
  @apply bg-primaryDark;
}
.card--applied:hover .card-content__actions .icon {
  @apply ml-4;
}
.card--applied:hover .card-content__actions .icon svg {
  @apply fill-primaryDark;
}
.card--news .card-header {
  @apply aspect-video;
}
.card--news .card-header img {
  @apply w-full h-full object-cover object-center;
  transition: transform 0.5s ease-in-out;
}
.card--product .card-header, .card--applied .card-header {
  @apply flex items-center aspect-[3/2] bg-white;
}
.card--product .card-header img, .card--applied .card-header img {
  @apply mx-auto;
  transition: transform 0.5s ease-in-out;
}
.card--product .card-header img {
  @apply mx-auto h-[150px] lg:h-[200px];
}
.card--applied .card-header {
  @apply border border-[#E8E8E8] rounded-rg prose-img:h-18;
}
@media (min-width: 1030px) {
  .card--applied .card-header {
    @apply prose-img:h-[125px];
  }
}
.card--category .card-header {
  @apply aspect-[2/1] prose-img:w-full prose-img:h-full prose-img:object-center prose-img:object-cover;
}
.card--category .card-header img {
  transition: transform 0.5s ease-in-out;
}
.card--control .card-header {
  @apply mb-4 prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;
}
@media (min-width: 1030px) {
  .card--control .card-header {
    @apply mb-6;
    height: 255px;
  }
}
.card--tech .card-header {
  @apply mb-5 aspect-video prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;
}
.card--news .card-content__title {
  @apply prose-headings:line-clamp-2 prose-headings:h-10;
}
.card--news .card-content__excerpt {
  @apply prose-p:line-clamp-3 prose-p:h-18;
}
.card--news .card-content__actions {
  @apply relative;
}
.card--news .card-content__actions::before {
  content: "";
  height: 1px;
  @apply absolute bottom-0 left-0 right-0 bg-primary;
}
.card--product .card-content {
  @apply px-4 lg:px-8;
}
.card--product .card-content__title {
  @apply prose-headings:font-normal;
}
.card--product .card-content__excerpt {
  @apply prose-p:line-clamp-3 prose-p:h-18;
}
.card--product .card-content__actions {
  @apply font-bold hover:bg-white;
}
.card--value .card-content {
  @apply prose-headings:mb-3;
}
@media (min-width: 1030px) {
  .card--value .card-content {
    @apply prose-headings:mb-6;
  }
}
.card--category .card-content {
  @apply -mt-8 lg:-mt-10;
}
.card--category .card-content__title {
  @apply prose-headings:font-normal prose-headings:text-primary;
}
@media (min-width: 1030px) {
  .card--category .card-content__title {
    @apply prose-headings:text-4xl;
  }
}
.card--category .card-content__title > h4 {
  @apply inline-block py-4 px-12 lg:px-24 bg-white bg-opacity-50 rounded-rg;
  backdrop-filter: blur(30px);
}
.card--applied .card-content__title {
  @apply mb-4 prose-headings:text-rg;
}
@media (min-width: 1030px) {
  .card--applied .card-content__title {
    @apply mb-7;
  }
}
.card--applied .card-content__actions {
  @apply relative;
}
.card--applied .card-content__actions::before {
  content: "";
  height: 1px;
  @apply absolute bottom-0 left-0 right-0 bg-primary;
}
.card--control .card-content__title {
  @apply mb-4 prose-headings:mb-3 prose-headings:text-1xl;
}
@media (min-width: 1030px) {
  .card--control .card-content__title {
    @apply mb-8 prose-headings:mb-4 prose-headings:text-5xl;
  }
}
.card--location .card-content__text {
  @apply mb-3;
}
@media (min-width: 1030px) {
  .card--location .card-content {
    @apply flex items-start;
  }
  .card--location .card-content__text {
    @apply flex-1 mb-0 pr-4;
  }
}

.media {
  @apply flex;
}
.media--contact {
  @apply p-4;
}
@media (min-width: 1030px) {
  .media--contact {
    @apply py-8 px-5;
  }
}
.media--result {
  @apply border-b border-b-gray;
}
@media (min-width: 1030px) {
  .media--result {
    @apply px-8 pb-6 mb-6;
  }
}
.media--result:hover .media-image img {
  @apply scale-110;
}
.media--result .media-image {
  @apply aspect-square rounded-sm overflow-hidden prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;
  width: 120px;
}
.media--result .media-image img {
  transition: transform 0.5s ease-in-out;
}
.media--contact .media-content {
  @apply pl-3 font-bold prose-a:text-primary;
}
@media (min-width: 1030px) {
  .media--contact .media-content {
    @apply pl-5 text-1xl prose-headings:text-1xl;
  }
}
@media (min-width: 1030px) {
  .media--result .media-content {
    @apply pl-10;
  }
}
.media--result .media-content__actions {
  @apply border-b border-b-transparent;
}

.form-group__input {
  @apply flex;
}
.form-group__add-text {
  @apply bg-white px-4 py-3 border border-[#E8E8E8] border-l-0 font-bold rounded-r-sm;
}
.form-input, .form-textarea, .form-select {
  @apply text-black border-[#D0D5DD] w-full px-4 py-3 rounded-sm transition ease-in-out ring-0 placeholder:text-graySemi focus:border-primary !important;
}
.form-input[disabled], .form-textarea[disabled], .form-select[disabled] {
  @apply bg-gray;
}
.form-group__input .form-input, .form-group__input .form-textarea, .form-group__input .form-select {
  @apply rounded-r-none !important;
}
.form-validate .form-input, .form-validate .form-textarea, .form-validate .form-select {
  @apply border-primary !important;
}
.form-validate__feedback {
  @apply mt-1 text-primary;
}
.form-validate__feedback small {
  @apply text-sm;
}

@keyframes checkmark {
  0% {
    background-position-y: 5px;
  }
  50% {
    background-position-y: -2px;
  }
  100% {
    background-position-y: 0;
  }
}
.form-check {
  @apply relative inline-flex items-center cursor-pointer;
}
.form-check input[type=checkbox]:checked {
  @apply bg-primary border-primary;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
  animation: checkmark 0.2s ease-in;
}
.form-check input[type=radio] {
  transition: 0.25s;
}
.form-check input[type=radio]:checked {
  @apply bg-primary border-primary;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
  animation: checkmark 0.2s ease-in;
}
.form-check label {
  @apply inline-block flex-1 pl-4 mb-0 cursor-pointer;
}
.list-form__choose .form-check label {
  @apply text-primary;
}
@media (min-width: 1030px) {
  .list-form__choose .form-check label {
    @apply text-2xl;
  }
}

.form-file {
  @apply relative;
}
.form-file input[type=file] {
  @apply absolute top-0 left-0 opacity-0 invisible;
}
.form-file label {
  @apply relative block lg:py-10 p-8 text-center border border-dashed border-[#c1c1c1] rounded-rg cursor-pointer overflow-hidden;
}
.form-file label .icon {
  @apply font-bold;
}
.form-file label .icon-image {
  @apply mb-2;
}
.form-file label .icon-image img {
  @apply h-8 mx-auto;
}
.form-file label .info {
  @apply text-sm;
}

.navbar {
  height: var(--navbar-height);
  transition: ease-in-out 0.25s, height 0.25s;
}
.navbar--fixed {
  @apply shadow;
}
.navbar-top {
  @apply h-8;
}
.navbar-main {
  height: calc(var(--navbar-height) - 32px);
}
.navbar-logo {
  @apply mr-auto;
}
.navbar-logo img {
  @apply h-12 lg:h-20;
}
.navbar-menu {
  @apply hidden lg:flex;
}
.navbar-menu__list a {
  @apply hover:text-primary;
}
.navbar-menu__list a:hover svg {
  @apply fill-primary;
}
.navbar-social img {
  @apply h-4;
}
.navbar-actions__row > div {
  @apply h-full px-4 border-l border-l-white;
}
.navbar-actions img {
  @apply h-4;
}

.treeview > a {
  @apply flex items-center;
}
.treeview > a span {
  @apply ml-auto;
}
.treeview__menu {
  @apply hidden;
}
.treeview__menu-list {
  @apply ml-4 mt-4;
}
.treeview__open > a > span {
  transform: rotate(-90deg);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.dropdown-toggle {
  @apply inline-flex items-center;
}
.dropdown--navbar .dropdown-toggle {
  @apply after:hidden;
}
.dropdown--navbar .dropdown-toggle.show {
  @apply text-primary;
}
.dropdown--navbar .dropdown-toggle.show svg {
  @apply fill-primary;
}
.dropdown-toggle .arrow {
  @apply ml-2;
}
.dropdown-menu {
  @apply bg-white p-4 shadow rounded-xs;
}
@media (min-width: 1030px) {
  .dropdown-menu {
    min-width: max-content;
  }
}
.dropdown--lang .dropdown-menu__list img {
  @apply h-4;
}

.accordion--career .accordion-item {
  @apply rounded-sm border border-transparent;
}
.accordion--career .accordion-item.active {
  @apply border-primary;
}
.accordion-toggle[aria-expanded=true] {
  transition-duration: 0.75s;
  transition-property: background-color, border-color;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.accordion-toggle[aria-expanded=true] .icon svg {
  @apply -rotate-90;
}
.accordion-toggle[aria-expanded=false] {
  transition-duration: 0.75s;
  transition-property: background-color, border-color;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.accordion-toggle .title {
  @apply flex flex-1 pr-4;
}
@media (min-width: 1030px) {
  .accordion-toggle .title {
    @apply items-center pr-6 prose-headings:text-1xl;
  }
}
.accordion-toggle .title-icon {
  @apply pt-2;
}
@media (min-width: 1030px) {
  .accordion-toggle .title-icon {
    @apply pl-4 pt-0;
  }
}
.accordion--hero .accordion-toggle .title {
  @apply flex-col;
}
@media (min-width: 1030px) {
  .accordion--hero .accordion-toggle .title {
    @apply flex-row prose-headings:text-5xl;
  }
}
.accordion--career .accordion-toggle .title {
  @apply flex-col;
}
@media (min-width: 700px) {
  .accordion--career .accordion-toggle .title {
    @apply flex-row;
  }
}
.accordion-toggle .icon {
  @apply inline-flex items-center justify-center w-6 h-6 rounded-full border border-primary;
}
.accordion-toggle .icon svg {
  @apply h-4 fill-primary;
  transition-duration: 0.5s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.accordion-content {
  transition-duration: 0.5s;
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.accordion-content[aria-hidden=true] {
  height: 0;
  opacity: 0;
  transform: translate3d(0, -0.5rem, 0);
  visibility: hidden;
}
.accordion-content[aria-hidden=false] {
  height: auto;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  visibility: visible;
}

.jumbotron--ground {
  @apply flex py-8 bg-cover;
  height: 385px;
}
@media (min-width: 1030px) {
  .jumbotron--ground {
    @apply items-center justify-center;
  }
}
.jumbotron--ground::before {
  content: "";
  @apply bg-white bg-opacity-60 absolute top-0 right-0 w-full h-full;
  background: linear-gradient(180deg, rgb(255, 255, 255) 60%, rgba(255, 255, 255, 0.3) 100%);
  mix-blend-mode: screen;
}
@media (min-width: 1030px) {
  .jumbotron--ground::before {
    background: linear-gradient(90deg, rgb(255, 255, 255) 50%, rgba(255, 255, 255, 0.3) 80%);
  }
}
.jumbotron--ground .jumbotron-body {
  @apply mr-auto;
}
@media (min-width: 1030px) {
  .jumbotron--ground .jumbotron-body {
    @apply prose-headings:mb-4 prose-headings:text-5xl;
    width: 500px;
  }
}

.tab-link {
  @apply py-3 px-5 bg-[#E8E8E8];
}
@media (min-width: 1030px) {
  .tab-link {
    @apply px-16;
  }
}
.tab-link[aria-selected=true] {
  @apply bg-grayDark text-white;
}
.tab-content .post-detail {
  @apply pt-8;
}
@media (min-width: 1030px) {
  .tab-content .post-detail {
    @apply pt-12;
  }
}

.modal.open {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 0.25s;
}

.searchbox-input {
  @apply bg-transparent p-0 pr-4 h-8 border-0 text-sm text-white placeholder:text-white !important;
}
.searchbox-submit {
  width: 32px;
  height: 32px;
}

.hamburger {
  @apply relative block lg:hidden w-10 h-10;
}
.hamburger-push {
  @apply flex items-center w-full h-full transition ease-in-out;
}
.hamburger-push > span {
  @apply bg-primary absolute top-5 block w-10 h-1 transition ease-in-out;
}
.hamburger-push > span::before, .hamburger-push > span::after {
  content: "";
  @apply bg-primary absolute w-10 h-1 transition ease-in-out;
}
.hamburger-push > span::before {
  @apply -top-[10px];
}
.hamburger-push > span::after {
  @apply top-[10px];
}
.hamburger-push.show > span {
  @apply bg-transparent;
}
.hamburger-push.show > span::before {
  transform: rotateZ(45deg) scaleX(1) translate(8px, 8px);
}
.hamburger-push.show > span::after {
  transform: rotateZ(-45deg) scaleX(1) translate(7px, -7px);
}
.hamburger-menu {
  top: var(--navbar-height);
  transform: translate3d(0, -100%, 0);
}
.hamburger-menu.show {
  @apply visible opacity-100;
  transform: translate3d(0, 0, 0);
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
.about-intro__body-image .play {
  @apply absolute top-1/2 left-1/2;
  transform: translate(-50%, -50%);
}
.play-button {
  @apply bg-primary relative w-20 h-20 transition ease-in-out;
}
.play-button:hover {
  @apply bg-primaryDark;
  box-shadow: 0 0 0 16px rgba(255, 255, 255, 0.3);
}

.pagination-item {
  @apply px-2;
}
.pagination-item__button.current {
  @apply text-primary text-2xl font-bold;
}

.tooltip {
  --tooltip-background-color: #354856;
  --tooltip-text-color: #fff;
  --tooltip-position-top: auto;
  --tooltip-position-bottom: calc(100% + 8px);
  --tooltip-position-border-top: auto;
  --tooltip-position-border-bottom: 100%;
  --tooltip-position-left: 50%;
  --tooltip-position-right: auto;
  --tooltip-position-border-left: 50%;
  --tooltip-position-border-right: auto;
  @apply relative inline-flex;
}
.tooltip::before, .tooltip::after {
  @apply absolute pointer-events-none opacity-0 transition-opacity;
}
.tooltip::before {
  content: attr(data-tooltip);
  color: var(--tooltip-text-color);
  background-color: var(--tooltip-background-color);
  @apply absolute w-max py-1 px-2 text-sm;
}
.tooltip::after {
  @apply block w-0 h-0 border-solid border-4;
  content: "";
  border-color: var(--tooltip-background-color) transparent transparent transparent;
}
.tooltip:hover::before, .tooltip:hover::after {
  @apply opacity-100;
  transition-delay: 75ms;
}
.tooltip--position::before, .tooltip--position::after {
  transform: translate(-50%);
}
.tooltip--position::before {
  top: var(--tooltip-position-top);
  bottom: var(--tooltip-position-bottom);
  left: var(--tooltip-position-left);
  right: var(--tooltip-position-right);
}
.tooltip--position::after {
  top: var(--tooltip-position-border-top);
  bottom: var(--tooltip-position-border-bottom);
  left: var(--tooltip-position-border-left);
  right: var(--tooltip-position-border-right);
  border-color: var(--tooltip-background-color) transparent transparent transparent;
}

.loading {
  @apply inline-block aspect-square pointer-events-none;
  background-color: currentColor;
  width: var(--loading-size);
  mask-size: 100%;
  mask-repeat: no-repeat;
  mask-position: center;
}
.loading-sm {
  width: var(--loading-sm-size);
}
.loading-lg {
  width: var(--loading-lg-size);
}
.loading-xl {
  width: var(--loading-xl-size);
}
.loading-primary {
  color: var(--primary-color);
}
.loading-secondary {
  color: var(--secondary-color);
}
.loading-spinner {
  mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjQnIGhlaWdodD0nMjQnIHN0cm9rZT0nIzAwMCcgdmlld0JveD0nMCAwIDI0IDI0JyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxzdHlsZT4uc3Bpbm5lcl9WOG0xe3RyYW5zZm9ybS1vcmlnaW46Y2VudGVyO2FuaW1hdGlvbjpzcGlubmVyX3pLb2EgMnMgbGluZWFyIGluZmluaXRlfS5zcGlubmVyX1Y4bTEgY2lyY2xle3N0cm9rZS1saW5lY2FwOnJvdW5kO2FuaW1hdGlvbjpzcGlubmVyX1lwWlMgMS41cyBlYXNlLW91dCBpbmZpbml0ZX1Aa2V5ZnJhbWVzIHNwaW5uZXJfektvYXsxMDAle3RyYW5zZm9ybTpyb3RhdGUoMzYwZGVnKX19QGtleWZyYW1lcyBzcGlubmVyX1lwWlN7MCV7c3Ryb2tlLWRhc2hhcnJheTowIDE1MDtzdHJva2UtZGFzaG9mZnNldDowfTQ3LjUle3N0cm9rZS1kYXNoYXJyYXk6NDIgMTUwO3N0cm9rZS1kYXNob2Zmc2V0Oi0xNn05NSUsMTAwJXtzdHJva2UtZGFzaGFycmF5OjQyIDE1MDtzdHJva2UtZGFzaG9mZnNldDotNTl9fTwvc3R5bGU+PGcgY2xhc3M9J3NwaW5uZXJfVjhtMSc+PGNpcmNsZSBjeD0nMTInIGN5PScxMicgcj0nOS41JyBmaWxsPSdub25lJyBzdHJva2Utd2lkdGg9JzMnPjwvY2lyY2xlPjwvZz48L3N2Zz4=);
}
.loading-ring {
  mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nNDQnIGhlaWdodD0nNDQnIHZpZXdCb3g9JzAgMCA0NCA0NCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJyBzdHJva2U9JyNmZmYnPjxnIGZpbGw9J25vbmUnIGZpbGwtcnVsZT0nZXZlbm9kZCcgc3Ryb2tlLXdpZHRoPScyJz48Y2lyY2xlIGN4PScyMicgY3k9JzIyJyByPScxJz48YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSdyJyBiZWdpbj0nMHMnIGR1cj0nMS44cycgdmFsdWVzPScxOyAyMCcgY2FsY01vZGU9J3NwbGluZScga2V5VGltZXM9JzA7IDEnIGtleVNwbGluZXM9JzAuMTY1LCAwLjg0LCAwLjQ0LCAxJyByZXBlYXRDb3VudD0naW5kZWZpbml0ZScgLz48YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSdzdHJva2Utb3BhY2l0eScgYmVnaW49JzBzJyBkdXI9JzEuOHMnIHZhbHVlcz0nMTsgMCcgY2FsY01vZGU9J3NwbGluZScga2V5VGltZXM9JzA7IDEnIGtleVNwbGluZXM9JzAuMywgMC42MSwgMC4zNTUsIDEnIHJlcGVhdENvdW50PSdpbmRlZmluaXRlJyAvPjwvY2lyY2xlPjxjaXJjbGUgY3g9JzIyJyBjeT0nMjInIHI9JzEnPjxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9J3InIGJlZ2luPSctMC45cycgZHVyPScxLjhzJyB2YWx1ZXM9JzE7IDIwJyBjYWxjTW9kZT0nc3BsaW5lJyBrZXlUaW1lcz0nMDsgMScga2V5U3BsaW5lcz0nMC4xNjUsIDAuODQsIDAuNDQsIDEnIHJlcGVhdENvdW50PSdpbmRlZmluaXRlJyAvPjxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9J3N0cm9rZS1vcGFjaXR5JyBiZWdpbj0nLTAuOXMnIGR1cj0nMS44cycgdmFsdWVzPScxOyAwJyBjYWxjTW9kZT0nc3BsaW5lJyBrZXlUaW1lcz0nMDsgMScga2V5U3BsaW5lcz0nMC4zLCAwLjYxLCAwLjM1NSwgMScgcmVwZWF0Q291bnQ9J2luZGVmaW5pdGUnIC8+PC9jaXJjbGU+PC9nPjwvc3ZnPg==);
}

.share-item {
  @apply pl-4;
}
.share-item__link {
  @apply prose-img:h-5;
}

.post-title__tag {
  @apply mb-2 text-sm;
}
.post-title__share {
  @apply flex items-center mt-2 prose-p:mr-3;
}
.post-title__share-item {
  @apply prose-img:h-5;
}
@media (min-width: 1030px) {
  .post-title {
    @apply prose-headings:text-5xl;
  }
}
.post-image {
  @apply aspect-video rounded-rg overflow-hidden prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;
}
.post-detail {
  @apply prose-headings:mb-2 prose-p:mb-4 prose-a:text-primary prose-a:font-bold prose-ul:mb-4 prose-ol:mb-4 prose-ul:pl-4 prose-ol:pl-4 prose-ul:list-disc prose-ol:list-decimal;
}
.post-detail p:last-child {
  margin-bottom: 0 !important;
}

.cta-banner__body {
  @apply items-center justify-center prose-headings:mb-8;
}
@media (min-width: 1030px) {
  .cta-banner__body {
    @apply flex prose-headings:mr-10 prose-headings:mb-0;
  }
}

.section {
  @apply py-16 lg:py-24;
}
.section-title {
  @apply prose-headings:text-3xl lg:prose-headings:text-8xl;
}
.section-title .captions {
  @apply mt-4;
}
.home-intro .section-title, .home-featured .section-title, .home-point .section-title, .home-news .section-title, .about-value .section-title, .product-applied .section-title, .news-related .section-title {
  @apply prose-headings:font-light;
}
.home-intro .section-title .captions, .home-featured .section-title .captions, .product-applied .section-title .captions {
  @apply mx-auto lg:max-w-[580px];
}
.home-about .section-title {
  @apply mt-14 lg:mt-20;
}
@media (min-width: 1030px) {
  .home-point .section-title {
    @apply flex flex-wrap w-5/6 prose-headings:leading-normal prose-headings:flex-1 prose-headings:text-4xl;
  }
  .home-point .section-title .captions {
    @apply pl-6 mt-0 w-[55%];
  }
}
.home-news .section-title {
  @apply flex items-center justify-between;
}
.home-news .section-title .captions {
  @apply mt-0;
}
.services-intro .section-title .captions {
  @apply mx-auto mt-3;
}
@media (min-width: 1030px) {
  .services-intro .section-title .captions {
    @apply w-3/4;
  }
}
@media (min-width: 1030px) {
  .services-package .section-title, .control-package .section-title, .tech-intro .section-title, .distribution-intro .section-title, .contact-point .section-title, .contact-location .section-title, .page-template .section-title {
    @apply prose-headings:text-5xl;
  }
}
.tech-intro .section-title {
  @apply pb-6 border-b border-b-[#E8E8E8];
}
.tech-intro .section-title .captions {
  @apply mx-auto;
}
@media (min-width: 1030px) {
  .tech-intro .section-title .captions {
    @apply mt-6 w-3/4;
  }
}
.contact-location .section-title .captions {
  @apply mt-2;
}

.main-content {
  margin-top: var(--navbar-height);
}

.float-link {
  @apply inline-flex items-center justify-center w-14 h-14 rounded-full bg-primary hover:bg-primaryDark prose-img:h-8;
}
.float-link.show .opened {
  @apply hidden;
}
.float-link.show .closed {
  @apply block;
}
.float-group {
  @apply bottom-20 left-4;
  transform: translate3d(0, 100%, 0);
}
@media (min-width: 1030px) {
  .float-group {
    left: unset;
  }
}
.float-group.show {
  @apply visible opacity-100;
  transform: translate3d(0, 0, 0);
}
.float-group__field {
  @apply bg-white p-4 rounded-rg border border-gray overflow-hidden;
}
@media (min-width: 1030px) {
  .float-group__field {
    @apply p-6;
    width: 480px;
  }
}

.footer-main__title-list-item {
  @apply inline-flex prose-img:h-4 prose-img:mx-auto;
}
.footer-main__title-list-item .icon {
  @apply w-4 mr-3;
  margin-top: 2px;
}
@media (min-width: 1030px) {
  .footer-main__menu {
    margin-top: 36px;
  }
}
.footer-social__body {
  @apply lg:flex items-center lg:justify-between;
}
@media (max-width: 1029.98px) {
  .footer-social__image {
    @apply justify-center mb-8;
  }
}
@media (max-width: 1029.98px) {
  .footer-social__list {
    @apply justify-center;
  }
}
.footer-social__list-item {
  @apply w-10 h-10 bg-graySemi rounded-full overflow-hidden prose-img:h-5;
}

.home-about {
  @apply bg-top bg-cover;
}
.home-about__body {
  @apply lg:w-3/5;
}
.home-about__body .actions-link {
  @apply relative;
}
.home-about__body .actions-link::before {
  content: "";
  height: 1px;
  bottom: -2px;
  @apply absolute left-0 right-0 bg-white;
}
.home-about__body .actions-link:hover .icon {
  @apply ml-4;
}
.home-about__play .linked {
  @apply relative bg-primary inline-flex items-center justify-center rounded-full z-10;
}
@media (min-width: 1030px) {
  .home-about__play .linked {
    @apply w-20 h-20;
  }
}
.home-about__play .linked::before {
  content: "";
  @apply bg-transparent absolute border-8 border-white border-opacity-30 w-24 h-24 rounded-full opacity-0 transition ease-in-out;
}
.home-about__play .linked:hover {
  @apply bg-primaryDark;
}
.home-about__play .linked:hover::before {
  @apply opacity-100;
}
.home-about__play .linked svg {
  @apply w-6 fill-white;
}
.home-about__image {
  @apply prose-img:h-16;
}
.home-point {
  @apply bg-right-top;
}
@media (max-width: 699.98px) {
  .home-point {
    background-size: 25%;
  }
}

.about-intro__body-content {
  @apply prose-headings:mb-4 prose-headings:text-2xl;
}
@media (min-width: 1030px) {
  .about-intro__body-content {
    @apply prose-headings:text-4xl prose-headings:mb-6;
  }
}
.about-intro__body-content .post-detail {
  @apply mb-6;
}
@media (min-width: 1030px) {
  .about-intro__body-content .post-detail {
    @apply mb-10;
  }
}
.about-value {
  @apply bg-right-top;
  background-size: 25%;
}
@media (min-width: 700px) {
  .about-value {
    background-size: 150px;
  }
}

@media (max-width: 699.98px) {
  .product-applied {
    @apply bg-center !important;
  }
}
.product-applied__body {
  @apply mx-auto;
}
@media (min-width: 1030px) {
  .product-applied__body {
    @apply w-5/6;
  }
}
.product-applied__left, .product-applied__right {
  @apply w-full p-5;
}
@media (min-width: 1030px) {
  .product-applied__left {
    @apply w-1/4;
  }
}
@media (min-width: 1030px) {
  .product-applied__right {
    @apply w-3/4;
  }
}
@media (min-width: 1030px) {
  .product-applied__result .filtering {
    @apply flex items-end;
  }
}
@media (max-width: 1029.98px) {
  .product-applied__result .filtering-result {
    @apply mb-4;
  }
}
.product-applied__result .filtering-result span {
  @apply font-normal text-primary;
}
@media (min-width: 1030px) {
  .product-applied__result .filtering-form {
    @apply ml-auto;
    width: 350px;
  }
}
.product-applied__result .filtering-form__input {
  @apply py-1 px-4 italic text-sm rounded-full !important;
}
.product-applied__result .filtering-form__submit {
  @apply absolute right-4 top-1/2 -translate-y-1/2;
}
.product-applied__sticky {
  position: sticky;
  top: 180px;
}
.product-applied__sticky .title {
  @apply mb-6;
}
@media (min-width: 1030px) {
  .product-applied__sticky .title {
    @apply mb-8;
  }
}
.product-applied__sticky .list-form__choose {
  @apply mb-6;
}
@media (min-width: 1030px) {
  .product-applied__sticky .list-form__choose {
    @apply mb-8;
  }
}
.product-applied__carousel-left, .product-applied__carousel-right {
  @apply w-full p-4 lg:p-8;
}
.product-applied__carousel-left {
  @apply prose-headings:mb-4;
}
@media (max-width: 1029.98px) {
  .product-applied__carousel-left {
    @apply order-2;
  }
}
@media (min-width: 1030px) {
  .product-applied__carousel-left {
    @apply w-2/5;
  }
}
.product-applied__carousel-left .actions {
  @apply mb-10;
}
@media (max-width: 1029.98px) {
  .product-applied__carousel-right {
    @apply order-1;
  }
}
@media (min-width: 1030px) {
  .product-applied__carousel-right {
    @apply w-3/5;
  }
}
.product-applied__calculator .field {
  @apply bg-white p-4 bg-opacity-50 rounded-rg;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
}
@media (min-width: 1030px) {
  .product-applied__calculator .field {
    @apply py-12 px-14 rounded-[32px];
  }
}
.product-applied__calculator .field-title {
  @apply mb-4 pb-6 prose-headings:text-primary border-b border-b-grayLight;
}
@media (max-width: 699.98px) {
  .product-applied__calculator .field-title {
    @apply prose-headings:mb-4;
  }
}
@media (min-width: 1030px) {
  .product-applied__calculator .field-title {
    @apply mb-8 pb-10 flex items-center prose-p:flex-1 prose-p:pl-22 prose-headings:text-8xl;
  }
}
.product-applied__calculator .field-group__list {
  @apply gap-10;
}
@media (min-width: 1030px) {
  .product-applied__calculator .field-group__list {
    @apply gap-16;
  }
}
.product-applied__calculator .field-group__item .title {
  @apply mb-6;
}
@media (min-width: 1030px) {
  .product-applied__calculator .field-group__item .title {
    @apply mb-8;
  }
}
.product-applied__calculator .field-group__submit {
  @apply mt-10;
}
@media (min-width: 1030px) {
  .product-applied__calculator .field-group__submit {
    @apply mt-16;
  }
}
@media (min-width: 1030px) {
  .product-applied__calculator .done {
    width: 550px;
  }
}
.product-applied__calculator .done-title {
  @apply mb-10;
}
@media (min-width: 1030px) {
  .product-applied__calculator .done-title {
    @apply prose-headings:mb-3 prose-headings:text-4xl;
  }
}
.product-applied__calculator .done-field {
  @apply mb-10;
}
@media (min-width: 1030px) {
  .product-applied__calculator .success-image {
    @apply absolute left-0 bottom-0 w-1/2;
  }
}
@media (max-width: 698.98px) {
  .product-applied__calculator .success-content {
    @apply pb-16;
  }
}
@media (min-width: 1030px) {
  .product-applied__calculator .success-content {
    @apply ml-auto w-1/2 pl-16 py-18;
  }
}
.product-applied__calculator .success-content__title {
  @apply mb-8 prose-headings:mb-6;
}
@media (min-width: 1030px) {
  .product-applied__calculator .success-content__title {
    @apply prose-headings:text-4xl prose-p:text-sm;
  }
}

.services-intro {
  @apply py-10 lg:py-16;
}
.services-intro__image {
  @apply mb-10 rounded-rg overflow-hidden prose-img:w-full prose-img:h-full prose-img:object-center prose-img:object-cover;
  aspect-ratio: 8/3;
}
@media (min-width: 1030px) {
  .services-intro__image {
    @apply mb-16;
  }
}
.services-intro__post {
  @apply prose-h3:mb-6;
}
@media (min-width: 1030px) {
  .services-intro__post {
    @apply prose-h3:mb-8 prose-h3:text-5xl;
  }
}
.services-package {
  background-size: 75px;
}
@media (min-width: 1030px) {
  .services-package {
    background-size: 150px;
  }
}

.control-intro {
  @apply py-10 lg:py-16;
}
.control-intro__image {
  @apply rounded-rg overflow-hidden prose-img:w-full prose-img:h-full prose-img:object-center prose-img:object-cover;
  aspect-ratio: 8/3;
}
@media (min-width: 1030px) {
  .control-intro__post {
    @apply prose-h3:mb-8 prose-h3:text-5xl;
  }
}
.control-package {
  background-size: 75px;
}
@media (min-width: 1030px) {
  .control-package {
    background-size: 150px;
  }
}
@media (min-width: 1030px) {
  .control-package__post {
    @apply w-5/6;
  }
}

.tech-intro {
  background-size: 75px;
}
@media (min-width: 1030px) {
  .tech-intro {
    background-size: 150px;
  }
}

.career-list {
  background-size: 75px;
}
@media (min-width: 1030px) {
  .career-list {
    background-size: 150px;
  }
}
.career-list__left, .career-list__right {
  @apply w-full p-5;
}
@media (min-width: 1030px) {
  .career-list__left {
    @apply w-1/4;
  }
}
@media (min-width: 1030px) {
  .career-list__right {
    @apply w-3/4;
  }
}
.career-list__sticky .list-form__choose {
  @apply mb-6;
}
@media (min-width: 1030px) {
  .career-list__sticky .list-form__choose {
    @apply mb-8;
  }
}
.career-list__sticky .list-form__choose .form-check input[type=checkbox] {
  @apply w-4 h-4;
}
.career-list__sticky .list-form__choose .form-check label {
  @apply pl-3 text-black text-rg font-bold;
}
.career-form__group {
  @apply mx-auto p-6 bg-white rounded-rg overflow-hidden;
}
@media (min-width: 1030px) {
  .career-form__group {
    @apply w-5/6 py-10 px-20;
  }
}
@media (min-width: 1030px) {
  .career-form__group .title {
    @apply prose-headings:text-5xl;
  }
}

.contact-point {
  background-size: 75px;
}
@media (min-width: 1030px) {
  .contact-point {
    background-size: 150px;
  }
}
.contact-point__form-group {
  @apply mx-auto p-6 bg-white rounded-sm overflow-hidden;
}
@media (min-width: 1030px) {
  .contact-point__form-group {
    @apply w-5/6 py-10 px-20;
  }
}
@media (min-width: 1030px) {
  .contact-point__form-group .title {
    @apply prose-headings:text-5xl;
  }
}

.search-body__group-title {
  @apply border-b border-b-gray;
}
@media (min-width: 1030px) {
  .search-body__group-title {
    @apply pb-12 mb-6;
  }
}

.stray {
  @apply bg-contain;
  height: 50vh;
}
@media (min-width: 1030px) {
  .stray {
    @apply bg-cover;
    height: calc(100vh - var(--navbar-height));
  }
}