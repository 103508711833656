.tab {

    &-link {
        @apply py-3 px-5 bg-[#E8E8E8];

        @include media-breakpoint-up(lg) {
            @apply px-16;
        }

        &[aria-selected='true'] {
            @apply bg-grayDark text-white;
        }
    }

    &-content {

        .post-detail {
            @apply pt-8;

            @include media-breakpoint-up(lg) {
                @apply pt-12;
            }
        }
    }
}