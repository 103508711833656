.career {

    &-list {
        background-size: 75px;

        @include media-breakpoint-up(lg) {
            background-size: 150px;
        }

        &__left, &__right {
            @apply w-full p-5;
        }

        &__left {

            @include media-breakpoint-up(lg) {
                @apply w-1/4;
            }
        }

        &__right {

            @include media-breakpoint-up(lg) {
                @apply w-3/4;
            }
        }

        &__sticky {

            .list {

                &-form {

                    &__choose {
                        @apply mb-6;

                        @include media-breakpoint-up(lg) {
                            @apply mb-8;
                        }

                        .form-check {

                            input[type="checkbox"] {
                                @apply w-4 h-4;
                            }

                            label {
                                @apply pl-3 text-black text-rg font-bold;
                            }
                        } 
                    }
                }
            }
        }
    }

    &-form {

        &__group {
            @apply mx-auto p-6 bg-white rounded-rg overflow-hidden;

            @include media-breakpoint-up(lg) {
                @apply w-5/6 py-10 px-20;
            }

            .title {

                @include media-breakpoint-up(lg) {
                    @apply prose-headings:text-5xl;
                }
            }
        }
    }
}