.card {
    @apply transition ease-in-out overflow-hidden;

    &--news {
        
        &:hover {

            .card {

                &-header {

                    img {
                        @apply scale-110;
                    }
                }

                &-content {

                    &__title {
                        @apply prose-headings:text-primaryDark;
                    }

                    &__actions {
                        @apply text-primaryDark;

                        &::before {
                            @apply bg-primaryDark;
                        }

                        .icon {
                            @apply ml-4;

                            svg {
                                @apply fill-primaryDark;
                            }
                        }
                    }
                }
            }
        }
    }

    &--product {
        
        &:hover {

            .card {

                &-header {

                    img {
                        @apply scale-110;
                    }
                }

                &-content {

                    &__actions {

                        .icon {
                            @apply ml-4;
                        }
                    }
                }
            }
        }
    }

    &--value {
        @apply p-4;

        @include media-breakpoint-up(lg) {
            @apply py-10 px-8;
        }
    }

    &--category {

        &:hover {

            .card {

                &-header {

                    img {

                        @include media-breakpoint-up(lg) {
                            transform: scale(1.175);
                        }
                    }
                }

                &-content {

                    &__title {
                        @apply prose-headings:text-white;

                        > h4 {
                            @apply bg-primaryDark;
                        }
                    }
                }
            }
        }
    }

    &--applied {

        &:hover {

            .card {

                &-content {

                    &__actions {
                        @apply text-primaryDark;

                        &::before {
                            @apply bg-primaryDark;
                        }

                        .icon {
                            @apply ml-4;

                            svg {
                                @apply fill-primaryDark;
                            }
                        }
                    }
                }
            }
        }
    }

    &-header {
        
        .card--news & {
            @apply aspect-video;
     
            img {
                @apply w-full h-full object-cover object-center;
                transition: transform .5s ease-in-out;
            }
        }

        .card--product &, .card--applied & {
            @apply flex items-center aspect-[3/2] bg-white;

            img {
                @apply mx-auto;
                transition: transform .5s ease-in-out;
            }
        }

        .card--product & {

            img {
                @apply mx-auto h-[150px] lg:h-[200px];
            }

        }

        .card--applied & {
            @apply border border-[#E8E8E8] rounded-rg prose-img:h-18;

            @include media-breakpoint-up(lg) {
                @apply prose-img:h-[125px];
            }
        }

        .card--category & {
            @apply aspect-[2/1] prose-img:w-full prose-img:h-full prose-img:object-center prose-img:object-cover;

            img {
                transition: transform .5s ease-in-out;
            }
        }

        .card--control & {
            @apply mb-4 prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;
            
            @include media-breakpoint-up(lg) {
                @apply mb-6;
                height: 255px;
            }
        }

        .card--tech & {
            @apply mb-5 aspect-video prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;
        }
    }

    &-content {

        .card--news & {

            &__title {
                @apply prose-headings:line-clamp-2 prose-headings:h-10;
            }

            &__excerpt {
                @apply prose-p:line-clamp-3 prose-p:h-18;
            }

            &__actions {
                @apply relative;

                &::before {
                    content: '';
                    height: 1px;
                    @apply absolute bottom-0 left-0 right-0 bg-primary;
                }
            }
        }

        .card--product & {
            @apply px-4 lg:px-8;

            &__title {
                @apply prose-headings:font-normal;
            }

            &__excerpt {
                @apply prose-p:line-clamp-3 prose-p:h-18;
            }

            &__actions {
                @apply font-bold hover:bg-white;
            }
        }

        .card--value & {
            @apply prose-headings:mb-3;

            @include media-breakpoint-up(lg) {
                @apply prose-headings:mb-6;
            }
        }

        .card--category & {
            @apply -mt-8 lg:-mt-10;
            
            &__title {
                @apply prose-headings:font-normal prose-headings:text-primary;

                @include media-breakpoint-up(lg) {
                    @apply prose-headings:text-4xl;
                }

                > h4 {
                    @apply inline-block py-4 px-12 lg:px-24 bg-white bg-opacity-50 rounded-rg;
                    backdrop-filter: blur(30px);
                }
            }
        }

        .card--applied & {

            &__title {
                @apply mb-4 prose-headings:text-rg;

                @include media-breakpoint-up(lg) {
                    @apply mb-7;
                }
            }
            
            &__actions {
                @apply relative;

                &::before {
                    content: '';
                    height: 1px;
                    @apply absolute bottom-0 left-0 right-0 bg-primary;
                }
            }
        }

        .card--control & {

            &__title {
                @apply mb-4 prose-headings:mb-3 prose-headings:text-1xl;

                @include media-breakpoint-up(lg) {
                    @apply mb-8 prose-headings:mb-4 prose-headings:text-5xl;
                }
            }
        }

        .card--location & {

            &__text {
                @apply mb-3;
            }
            
            @include media-breakpoint-up(lg) {
                @apply flex items-start;

                &__text {
                    @apply flex-1 mb-0 pr-4;
                }
            }
        }
    }
}