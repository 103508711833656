.search {

    &-body {

        &__group {

            &-title {
                @apply border-b border-b-gray;

                @include media-breakpoint-up(lg) {
                    @apply pb-12 mb-6;
                }
            }
        }
    }
}