@import "~datatables.net-dt/css/dataTables.dataTables.css";
@import "~datatables.net-responsive-dt/css/responsive.dataTables.css";

.dt-paging.paging_full_numbers {
    @apply text-center;

    @include media-breakpoint-up(lg) {
        @apply mt-10;
    }
}

div.dt-container .dt-paging .dt-paging-button {
    @apply mx-2 p-0 font-bold text-[#484E54] appearance-none;
    min-width: unset;

    &:hover {
        @apply border-0 text-black #{!important};
        background: transparent !important;
    }
}

div.dt-container .dt-paging .dt-paging-button.current {
    @apply text-2xl text-primary border-0 #{!important};
    background: transparent;

    &:hover {
        @apply text-primaryDark border-0 #{!important};
        background: transparent !important;
    }
}

.dt-filter {
    @apply grid gap-8;
    
    @include media-breakpoint-up(lg) {
        @apply flex gap-10;
    }

    &__left {
        @apply inline-flex items-center;
    }

    .form {

        &-select {
            @apply ml-4 border-primary focus:border-primaryDark #{!important};

            @include media-breakpoint-up(lg) {
                width: 200px !important;
            }
        }

        &-input {
            @apply border-primary focus:border-primaryDark placeholder:italic #{!important};

            @include media-breakpoint-up(lg) {
                width: 450px !important;
            }
        }
    }
}

.dt-container {

    .dt {

        &-layout-row {

            .dt-search {
                @apply hidden;
            }
        }
    }
}

table.dataTable {

    > thead {

        > tr {

            > th {
                @apply p-3 border-b-0 bg-primaryDark text-white;
        
                @include media-breakpoint-up(lg) {
                    @apply px-10 text-1xl;
                }

                &:first-child {
                    @apply rounded-tl-sm;
                }

                &:last-child {
                    @apply rounded-tr-sm;
                }
            }
        }
    }

    > tbody {

        > tr {

            &:nth-of-type(even) {

                > td {
                    @apply bg-grayLight;
                }
            }

            > td {
                @apply p-3 font-bold;

                @include media-breakpoint-up(lg) {
                    @apply px-10;
                }
            }
        }
    }

    .dataTables_length {
        display: none;
    }
}