.control {

    &-intro {
        @apply py-10 lg:py-16;

        &__image {
            @apply rounded-rg overflow-hidden prose-img:w-full prose-img:h-full prose-img:object-center prose-img:object-cover;
            aspect-ratio: 8/3;
        }

        &__post {

            @include media-breakpoint-up(lg) {
                @apply prose-h3:mb-8 prose-h3:text-5xl;
            }
        }
    }

    &-package {
        background-size: 75px;

        @include media-breakpoint-up(lg) {
            background-size: 150px;
        }

        &__post {

            @include media-breakpoint-up(lg) {
                @apply w-5/6;
            }
        }
    }
}