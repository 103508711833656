.post {

    &-title {

        &__tag {
            @apply mb-2 text-sm;
        }

        &__share {
            @apply flex items-center mt-2 prose-p:mr-3;

            &-item {
                @apply prose-img:h-5;
            }
        }

        @include media-breakpoint-up(lg) {
            @apply prose-headings:text-5xl;
        }
    }

    &-image {
        @apply aspect-video rounded-rg overflow-hidden prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;
    }

    &-detail {
        @apply prose-headings:mb-2 prose-p:mb-4 prose-a:text-primary prose-a:font-bold prose-ul:mb-4 prose-ol:mb-4 prose-ul:pl-4 prose-ol:pl-4 prose-ul:list-disc prose-ol:list-decimal;

        p {

            &:last-child {
                margin-bottom: 0 #{!important};
            }
        }
    }
}