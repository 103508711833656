.home {

    &-about {
        @apply bg-top bg-cover;

        &__body {
            @apply lg:w-3/5;

            .actions {

                &-link {
                    @apply relative;

                    &::before {
                        content: '';
                        height: 1px;
                        bottom: -2px;
                        @apply absolute left-0 right-0 bg-white;
                    }

                    &:hover {

                        .icon {
                            @apply ml-4;
                        }
                    }
                }
            }
        }

        &__play {

            .linked {
                @apply relative bg-primary inline-flex items-center justify-center rounded-full z-10;
                
                @include media-breakpoint-up(lg) {
                    @apply w-20 h-20;
                }

                &::before {
                    content: '';
                    @apply bg-transparent absolute border-8 border-white border-opacity-30 w-24 h-24 rounded-full opacity-0 transition ease-in-out;
                }

                &:hover {
                    @apply bg-primaryDark;

                    &::before {
                        @apply opacity-100;
                    }
                }

                svg {
                    @apply w-6 fill-white;
                }
            }
        }

        &__image {
            @apply prose-img:h-16;
        }
    }

    &-point {
        @apply bg-right-top;

        @include media-breakpoint-down(md) {
            background-size: 25%;
        }
    }
}