@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

.play {

    .about-intro__body-image & {
        @apply absolute top-1/2 left-1/2;
        transform: translate(-50%, -50%);
    }

    &-button {
        @apply bg-primary relative w-20 h-20 transition ease-in-out;

        &:hover {
            @apply bg-primaryDark;
            box-shadow: 0 0 0 16px rgba(255, 255, 255, .3);
        }
    }
}