.navbar {
    height: var(--navbar-height);
    transition: ease-in-out .25s, height .25s;

    &--fixed {
        @apply shadow;
    }

    &-top {
        @apply h-8;
    }

    &-main {
        height: calc(var(--navbar-height) - 32px);
    }

    &-logo {
        @apply mr-auto;

        img {
            @apply h-12 lg:h-20;
        }
    }

    &-menu {
        @apply hidden lg:flex;

        &__list {

            a {
                @apply hover:text-primary;
                
                &:hover {

                    svg {
                        @apply fill-primary;
                    }
                }
            }
        }
    }

    &-social {

        img {
            @apply h-4;
        }
    }

    &-actions {

        &__row {

            > div {
                @apply h-full px-4 border-l border-l-white;
            }
        }

        img {
            @apply h-4;
        }
    }
}