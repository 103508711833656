.form {

    &-group {
       
        &__input {
            @apply flex;
        }

        &__add {

            &-text {
                @apply bg-white px-4 py-3 border border-[#E8E8E8] border-l-0 font-bold rounded-r-sm;
            }
        }
    }

    &-input, &-textarea, &-select {
        @apply text-black border-[#D0D5DD] w-full px-4 py-3 rounded-sm transition ease-in-out ring-0 placeholder:text-graySemi focus:border-primary #{!important};

        &[disabled] {
            @apply bg-gray;
        }

        .form-group__input & {
            @apply rounded-r-none #{!important};
        }
    }

    &-validate {

        .form {

            &-input, &-textarea, &-select {
                @apply border-primary #{!important};
            }
        }

        &__feedback {
            @apply mt-1 text-primary;

            small {
                @apply text-sm;
            }
        }
    }
}