.accordion {

    &-item {

        .accordion--career & {
            @apply rounded-sm border border-transparent;

            &.active {
                @apply border-primary;
            }
        }
    }

    &-toggle {

        &[aria-expanded=true] {
            transition-duration: .75s;
            transition-property: background-color, border-color;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

            .icon {

                svg {
                    @apply -rotate-90;
                }
            }
        }

        &[aria-expanded=false] {
            transition-duration: .75s;
            transition-property: background-color, border-color;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        .title {
            @apply flex flex-1 pr-4;

            @include media-breakpoint-up(lg) {
                @apply items-center pr-6 prose-headings:text-1xl;
            }

            &-icon {
                @apply pt-2;

                @include media-breakpoint-up(lg) {
                    @apply pl-4 pt-0;
                }
            }

            .accordion--hero & {
                @apply flex-col;

                @include media-breakpoint-up(lg) {
                    @apply flex-row prose-headings:text-5xl;
                }
            }

            .accordion--career & {
                @apply flex-col;

                @include media-breakpoint-up(md) {
                    @apply flex-row;
                }
            }
        }

        .icon {
            @apply inline-flex items-center justify-center w-6 h-6 rounded-full border border-primary;

            svg {
                @apply h-4 fill-primary;
                transition-duration: .5s;
                transition-property: transform;
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
    }

    &-content {
        transition-duration: .5s;
        transition-property: transform, opacity;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &[aria-hidden=true] {
            height: 0;
            opacity: 0;
            transform: translate3d(0, -0.5rem, 0);
            visibility: hidden;
        }

        &[aria-hidden=false] {
            height: auto;
            opacity: 1;
            transform: translate3d(0, 0, 0);
            visibility: visible;
        }
    }
}