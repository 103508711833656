.contact {

    &-point {
        background-size: 75px;

        @include media-breakpoint-up(lg) {
            background-size: 150px;
        }

        &__form {

            &-group {
                @apply mx-auto p-6 bg-white rounded-sm overflow-hidden;

                @include media-breakpoint-up(lg) {
                    @apply w-5/6 py-10 px-20;
                }

                .title {

                    @include media-breakpoint-up(lg) {
                        @apply prose-headings:text-5xl;
                    }
                }
            }
        }
    }
}