.cta {

    &-banner {

        &__body {
            @apply items-center justify-center prose-headings:mb-8;

            @include media-breakpoint-up(lg) {
                @apply flex prose-headings:mr-10 prose-headings:mb-0;
            }
        }
    }
}