@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

.dropdown {

    &-toggle {
        @apply inline-flex items-center;

        .dropdown--navbar & {
            @apply after:hidden;

            &.show {
                @apply text-primary;

                svg {
                    @apply fill-primary;
                }
            }
        }

        .arrow {
            @apply ml-2;
        }
    }

    &-menu {
        @apply bg-white p-4 shadow rounded-xs;

        @include media-breakpoint-up(lg) {
            min-width: max-content;
        }

        .dropdown--lang & {

            &__list {

                img {
                    @apply h-4;
                }
            }
        }
    }
}