.pagination {

    &-item {
        @apply px-2;

        &__button {

            &.current {
                @apply text-primary text-2xl font-bold;
            }
        }
    }
}