.float {

    &-link {
        @apply inline-flex items-center justify-center w-14 h-14 rounded-full bg-primary hover:bg-primaryDark prose-img:h-8;

        &.show {
            
            .opened {
                @apply hidden;
            }

            .closed {
                @apply block;
            }
        }
    }

    &-group {
        @apply bottom-20 left-4;
        transform: translate3d(0, 100%, 0);

        @include media-breakpoint-up(lg) {
            left: unset;
        }

        &.show {
            @apply visible opacity-100;
            transform: translate3d(0, 0, 0);
        }

        &__field {
            @apply bg-white p-4 rounded-rg border border-gray overflow-hidden;

            @include media-breakpoint-up(lg) {
                @apply p-6;
                width: 480px;
            }
        }
    }
}