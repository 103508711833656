.media {
    @apply flex;

    &--contact {
        @apply p-4;

        @include media-breakpoint-up(lg) {
            @apply py-8 px-5;
        }
    }

    &--result {
        @apply border-b border-b-gray;

        @include media-breakpoint-up(lg) {
            @apply px-8 pb-6 mb-6;
        }

        &:hover {

            .media {

                &-image {

                    img {
                        @apply scale-110;
                    }
                }
            }
        }
    }

    &-image {

        .media--result & {
            @apply aspect-square rounded-sm overflow-hidden prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;
            width: 120px;

            img {
                transition: transform .5s ease-in-out;
            }
        }
    }

    &-content {

        .media--contact & {
            @apply pl-3 font-bold prose-a:text-primary;
            
            @include media-breakpoint-up(lg) {
                @apply pl-5 text-1xl prose-headings:text-1xl;
            }
        }

        .media--result & {

            @include media-breakpoint-up(lg) {
                @apply pl-10;
            }

            &__actions {
                @apply border-b border-b-transparent;
            }
        }
    }
}