.product {

    &-applied {

        @include media-breakpoint-down(md) {
            @apply bg-center #{!important};
        }

        &__body {
            @apply mx-auto;

            @include media-breakpoint-up(lg) {
                @apply w-5/6;
            }
        }

        &__left, &__right {
            @apply w-full p-5;
        }

        &__left {

            @include media-breakpoint-up(lg) {
                @apply w-1/4;
            }
        }

        &__right {

            @include media-breakpoint-up(lg) {
                @apply w-3/4;
            }
        }

        &__result {

            .filtering {

                @include media-breakpoint-up(lg) {
                    @apply flex items-end;
                }

                &-result {

                    @include media-breakpoint-down(lg) {
                        @apply mb-4;
                    }

                    span {
                        @apply font-normal text-primary;
                    }
                }

                &-form {

                    @include media-breakpoint-up(lg) {
                        @apply ml-auto;
                        width: 350px;
                    }

                    &__input {
                        @apply py-1 px-4 italic text-sm rounded-full #{!important};
                    }

                    &__submit {
                        @apply absolute right-4 top-1/2 -translate-y-1/2;
                    }
                }
            }
        }

        &__sticky {
            position: sticky;
            top: 180px;

            .title {
                @apply mb-6;

                @include media-breakpoint-up(lg) {
                    @apply mb-8;
                }
            }

            .list {

                &-form {

                    &__choose {
                        @apply mb-6;

                        @include media-breakpoint-up(lg) {
                            @apply mb-8;
                        }
                    }
                }
            }
        }

        &__carousel {

            &-left, &-right {
                @apply w-full p-4 lg:p-8;
            }

            &-left {
                @apply prose-headings:mb-4;

                @include media-breakpoint-down(lg) {
                    @apply order-2;
                }

                @include media-breakpoint-up(lg) {
                    @apply w-2/5;
                }

                .actions {
                    @apply mb-10;
                }
            }

            &-right {

                @include media-breakpoint-down(lg) {
                    @apply order-1;
                }

                @include media-breakpoint-up(lg) {
                    @apply w-3/5;
                }
            }
        }

        &__calculator {

            .field {
                @apply bg-white p-4 bg-opacity-50 rounded-rg;
                backdrop-filter: blur(50px);
                -webkit-backdrop-filter: blur(50px);

                @include media-breakpoint-up(lg) {
                    @apply py-12 px-14 rounded-[32px];
                }

                &-title {
                    @apply mb-4 pb-6 prose-headings:text-primary border-b border-b-grayLight;

                    @include media-breakpoint-down(md) {
                        @apply prose-headings:mb-4;
                    }

                    @include media-breakpoint-up(lg) {
                        @apply mb-8 pb-10 flex items-center prose-p:flex-1 prose-p:pl-22 prose-headings:text-8xl;
                    }
                }

                &-group {

                    &__list {
                        @apply gap-10;

                        @include media-breakpoint-up(lg) {
                            @apply gap-16;
                        }
                    }

                    &__item {

                        .title {
                            @apply mb-6;

                            @include media-breakpoint-up(lg) {
                                @apply mb-8;
                            }
                        }
                    }

                    &__submit {
                        @apply mt-10;

                        @include media-breakpoint-up(lg) {
                            @apply mt-16;
                        }
                    }
                }
            }
            
            .done {

                @include media-breakpoint-up(lg) {
                    width: 550px;
                }

                &-title {
                    @apply mb-10;

                    @include media-breakpoint-up(lg) {
                        @apply prose-headings:mb-3 prose-headings:text-4xl;
                    }
                }

                &-field {
                    @apply mb-10;
                }
            }

            .success {

                &-image {
                    
                    @include media-breakpoint-up(lg) {
                        @apply absolute left-0 bottom-0 w-1/2;
                    }
                }

                &-content {

                    @include media-breakpoint-down(sm) {
                        @apply pb-16;
                    }

                    @include media-breakpoint-up(lg) {
                        @apply ml-auto w-1/2 pl-16 py-18;
                    }

                    &__title {
                        @apply mb-8 prose-headings:mb-6;

                        @include media-breakpoint-up(lg) {
                            @apply prose-headings:text-4xl prose-p:text-sm;
                        }
                    }
                }
            }
        }
    }
}