$slick-loader-path: "/assets/js/vendor/slick/" !default;
$slick-font-path: "/assets/js/vendor/slick/fonts/" !default;

@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

.slick {
    @apply hidden;

    &-dotted, &-slider {

        &.slick--banner {
            @apply mb-4 lg:mb-8;
        }
    }

    &-list {

        .slick--category & {
            overflow: unset;
        }
    }

    &-slide {

        .slick--banner & {

            .item {
                @apply block;

                &-image {
                    @apply aspect-[3/1] overflow-hidden;

                    img {
                        @apply w-full h-full object-center object-cover;
                    }
                }
            }
        }

        .slick--category & {
            @apply lg:w-[800px];

            .item {
                @apply block transition ease-in-out;

                &-image {
                    @apply aspect-[2/1] prose-img:w-full prose-img:h-full prose-img:object-center prose-img:object-cover;
                }

                &-content {
                    @apply -mt-8 lg:-mt-10 prose-headings:text-primary prose-headings:font-normal lg:prose-headings:text-4xl;

                    > h4 {
                        @apply inline-block py-4 px-12 lg:px-24 bg-white bg-opacity-50 rounded-rg;
                        backdrop-filter: blur(30px);
                    }
                }
            }
        }

        .slick--product & {
            @apply mx-2 lg:mx-5;
        }

        .slick--product-detail & {

            .item {

                &-image {
                    @apply prose-img:mx-auto prose-img:h-[175px];

                    @include media-breakpoint-up(lg) {
                        @apply prose-img:h-[300px];
                    }
                }
            }
        }
                    
        &.slick-active {
            
            .slick--category & {

                .item {

                    &-image {
                        @apply scale-110;
                    }

                    &-content {
                        @apply -mt-6 lg:-mt-5;
                    }

                    &:hover {

                        .item {
    
                            &-image {
                                
                                @include media-breakpoint-up(lg) {
                                    transform: scale(1.175);
                                }
                            }
    
                            &-content {
                                @apply lg:mt-0 prose-headings:text-white;
    
                                > h4 {
                                    @apply bg-primaryDark;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &-right, &-left {
        @apply w-10 h-10 inline-flex items-center justify-center bg-primary rounded-full overflow-hidden cursor-pointer z-10 transition ease-in-out hover:bg-primaryDark;

        .slick--banner &, .slick--category &, .slick--product-detail & {
            @apply absolute top-1/2 -translate-y-1/2;
        }

        .slick--product & {
            @apply absolute top-1/4 -translate-y-1/4;
        }
    }

    &-right {

        .slick--banner & {
            @apply right-2 lg:right-10;
        }

        .slick--category & {
            @apply right-4 lg:right-[300px];
        }

        .slick--product &, .slick--product-detail & {
            @apply right-0 lg:right-12;
        }
    }

    &-left {

        .slick--banner & {
            @apply left-2 lg:left-10;
        }

        .slick--category & {
            @apply left-4 lg:left-[300px];
        }

        .slick--product &, .slick--product-detail & {
            @apply left-0 lg:left-12;
        }
    }

    &-dots {
        @apply -bottom-4 lg:-bottom-8;

        > li {
            @apply inline-flex items-center justify-center w-3 h-3 mx-1;

            &.slick-active {

                button {
                    @apply rotate-45 bg-primary;
                }
            }

            button {
                @apply bg-gray w-2 h-2 before:hidden;
                border-radius: 2px;
            }
        }

        .slick--banner & {
            
        }
    }
}