.jumbotron {

    &--ground {
        @apply flex py-8 bg-cover;
        height: 385px;

        @include media-breakpoint-up(lg) {
            @apply items-center justify-center;
        }

        &::before {
            content: '';
            @apply bg-white bg-opacity-60 absolute top-0 right-0 w-full h-full;
            background: linear-gradient(180deg, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, .3) 100%);
            mix-blend-mode: screen;

            @include media-breakpoint-up(lg) {
                background: linear-gradient(90deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, .3) 80%);
            }
        }
    }

    &-body {

        .jumbotron--ground & {
            @apply mr-auto;

            @include media-breakpoint-up(lg) {
                @apply prose-headings:mb-4 prose-headings:text-5xl;
                width: 500px;
            }
        }
    }
}